/************************************************

Stylesheet: Global Stylesheet

*************************************************/

a,
button,
.button,
input,
textarea {
    transition: all 0.3s;
}

button {
    cursor: pointer;
}

strong {
    font-weight: 800;
}

body {
    background-color: #f3f3f3;
    overflow-x: hidden;
    background-image: url('../images/before.svg');
    background-repeat: no-repeat;
    background-position: -706px -103px;
    background-size: 1321px;
}

.button--outline {
    display: inline-grid;
    grid-auto-flow: column;
    grid-auto-columns: min-content;
    grid-gap: 20px;
    align-items: center;
    padding: 18px;
    border: 2px solid #f3f3f3;
    border-radius: 30px;
    background-color: transparent;
    color: #6d6f71;

    &.wm {
        padding: 14px 30px;

        span {
            padding: 0;
        }
    }

    span {
        display: inline-block;
        font-size: 14px;
        line-height: 21px;
        font-weight: 600;
        white-space: nowrap;
    }

    i {
        font-size: 14px;
    }
}

.button--modal {
    padding: 14px 30px;

    @media (max-width: 479px) {
        grid-column: 1 / -1;
    }
}

.landing1 {
    position: relative;
    padding-top: 80px;
    padding-bottom: 80px;

    @include breakpoint(large) {
        padding-top: 160px;
        padding-bottom: 120px;
    }

    &__before,
    &__after {
        position: absolute;
    }

    &__before {
        left: -36.77vw;
        top: -5.36vw;
        width: 68.8vw;
    }

    &__after {
        bottom: 0;
        right: 0;

        @include breakpoint(small only) {
            display: none !important;
        }
    }

    & > .grid-container {
        position: relative;
        z-index: 1;
        display: grid;
        grid-template-columns: minmax(0, 1fr);
        grid-gap: 50px 30px;

        @include breakpoint(large) {
            grid-template-columns: repeat(2, minmax(0, 1fr));
        }
    }

    &__photo {
        @include breakpoint(medium down) {
            justify-self: center;

            img,
            picture {
                max-width: 300px;
            }
        }
    }

    &__title {
        color: #010f27;
        font-weight: 600;
        font-size: 24px;
        line-height: 32px;
        margin-bottom: 50px;

        @include breakpoint(medium down) {
            text-align: center;
        }

        @include breakpoint(medium up) {
            font-size: 36px;
            line-height: 51px;
        }
    }

    &__description {
        color: #141414;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        margin-bottom: 51px;
    }

    &__row {
        display: flex;
        align-items: center;
        flex-wrap: nowrap;
    }

    &__caption {
        display: inline-block;
        color: #6d6f71;
        font-size: 13px;
        line-height: 20px;
        font-weight: 500;
        margin: 0 20px;
    }

    &__button {
        box-shadow: 0 10px 40px rgba(52, 215, 60, 0.3);
        border-radius: 30px;
        background-color: #34d73c;
        padding: 18px 30px;
        color: #fff !important;
        font-size: 16px;
        line-height: 24px;
        font-weight: 700;

        &:hover,
        &:focus {
            opacity: 0.9;
        }
    }

    &__phone {
        border-radius: 30px;
        background-color: #f3f3f3;
        padding: 5px 5px 5px 25px;
        display: inline-flex;
        align-items: center;
        transition: all 0.3s;

        &:hover,
        &:focus {
            background-color: #34d73c;
            color: #fff;

            a {
                color: #fff;
            }
        }

        a {
            color: #2b343c;
            font-size: 16px;
            line-height: 24px;
            font-weight: 700;
            display: inline-block;
            padding: 15px 25px 15px 0;
        }

        button {
            display: inline-grid;
            width: 50px;
            height: 50px;
            place-items: center center;
            background-color: #fff;
            color: #6d6f71;
            border-radius: 50%;

            &.active {
                opacity: 0;
            }

            &:hover,
            &:focus {
                opacity: 0.9;
            }

            i {
                transition: all 0.3s;
                font-size: 10px;
            }
        }
    }

    &__box {
        box-shadow: 0 30px 40px rgba(43, 52, 60, 0.1);
        border-radius: 30px;
        background-color: #fff;
        margin-bottom: 50px;

        &__top {
            padding: 40px;
            border-bottom: 2px solid #f3f3f3;
        }

        &__bottom {
            padding: 20px 40px 40px;
            display: flex;
            flex-wrap: wrap;
            gap: 10px;
        }

        &__title {
            color: #2b343c;
            font-weight: 500;
            font-size: 20px;
            line-height: 30px;
            margin-bottom: 42px;

            @include breakpoint(medium up) {
                font-size: 26px;
                line-height: 36px;
            }
        }
    }

    &__footer {
        color: #6d6f71;
        font-size: 13px;
        line-height: 24px;
        font-weight: 600;
        margin-bottom: 50px;
    }

    &__logos {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(110px, 1fr));
        place-items: center;
        grid-gap: 20px;
        background-color: #fff;
        border-radius: 10px;
        padding: 10px;
    }
}

.landing2 {
    position: relative;
    padding-block: 50px;

    @include breakpoint(medium up) {
        padding-block: 80px 120px;
    }

    &__title {
        color: #010f27;
        font-weight: 600;
        font-size: 24px;
        line-height: 32px;
        margin-bottom: 50px;

        @include breakpoint(medium down) {
            text-align: center;
        }

        @include breakpoint(medium up) {
            font-size: 36px;
            line-height: 51px;
        }
    }

    &__items {
        display: grid;
        gap: 30px;
        grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    }
}

#modal-video {
    .close-button {
        font-size: 40px;
        display: block;
        margin-left: auto;
    }
}

.responsive-embed {
    position: relative;
    aspect-ratio: 16 / 9;

    iframe,
    video {
        position: absolute;
        inset: 0;
        width: 100%;
        height: 100%;
    }
}
