@font-face {
  font-family: 'icomoon';
  src:  url('../fonts/icomoon.eot?etinpr');
  src:  url('../fonts/icomoon.eot?etinpr#iefix') format('embedded-opentype'),
    url('../fonts/icomoon.ttf?etinpr') format('truetype'),
    url('../fonts/icomoon.woff?etinpr') format('woff'),
    url('../fonts/icomoon.svg?etinpr#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-instagram:before {
  content: "\e904";
}
.icon-arrow:before {
  content: "\e900";
}
.icon-eye:before {
  content: "\e901";
}
.icon-linkedin:before {
  content: "\e902";
}
.icon-youtube:before {
  content: "\e903";
}
