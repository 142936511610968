/******************************************************************

Stylesheet: Mixins & Constants Stylesheet

This is where you can take advantage of Sass' great features:
Mixins & Constants.

******************************************************************/

/*********************
TOOLS
*********************/

// http://www.zeldman.com/2012/03/01/replacing-the-9999px-hack-new-image-replacement/
.image-replacement {
	text-indent: 100%;
	white-space: nowrap;
	overflow: hidden;
}


/*********************
COLORS
*********************/


/*********************
TYPOGRAPHY
*********************/
/*	@font-face {
    	font-family: 'Font Name';
    	src: url('assets/fonts/font-name.eot');
    	src: url('assets/fonts/font-name.eot?#iefix') format('embedded-opentype'),
             url('assets/fonts/font-name.woff') format('woff'),
             url('assets/fonts/font-name.ttf') format('truetype'),
             url('assets/fonts/font-name.svg#font-name') format('svg');
    	font-weight: normal;
    	font-style: normal;
	}
*/

//Use the best ampersand - http://simplebits.com/notebook/2008/08/14/ampersands-2/
span.amp {
  font-family: Baskerville,'Goudy Old Style',Palatino,'Book Antiqua',serif !important;
  font-style: italic;
}

/*********************
CSS3 GRADIENTS.
*********************/

/* @include css-gradient(#dfdfdf,#f8f8f8); */
@mixin css-gradient($from: #dfdfdf, $to: #f8f8f8) {
	background-color: $to;
	background-image: -webkit-gradient(linear, left top, left bottom, from($from), to($to));
	background-image: -webkit-linear-gradient(top, $from, $to);
	background-image: -moz-linear-gradient(top, $from, $to);
	background-image: -o-linear-gradient(top, $from, $to);
	background-image: linear-gradient(to bottom, $from, $to);
}

//kolorowe placeholdery
@mixin input-placeholder {
    &.placeholder { @content; }
    &:-moz-placeholder { @content; }
    &::-moz-placeholder { @content; }
    &:-ms-input-placeholder { @content; }
    &::-webkit-input-placeholder { @content; }
}

@mixin webicon {
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

// CSS variable shorthand
@function v($var, $fallback: null) {
    @if($fallback) {
        @return var(--#{$var}, #{$fallback});
    } @else {
        @return var(--#{$var});
    }
}

@mixin css-grid-classes {
    .grid-container {
        max-width: $global-width;
        margin-left: auto;
        margin-right: auto;

        @if (type-of($grid-container-padding) == 'map') {
            @include -zf-breakpoint-value(auto, $grid-container-padding) {
                $gutter: rem-calc($-zf-bp-value) * 0.5;

                padding-left: $gutter;
                padding-right: $gutter;
            }
        }
        @else if (type-of($grid-container-padding) == 'number') {
            $gutter: rem-calc($grid-container-padding) * 0.5;

            padding-left: $gutter;
            padding-right: $gutter;
        }

        &.full {
            max-width: none;
            padding-left: 0;
            padding-right: 0;
        }

        &.fluid {
            max-width: none;
        }

        &.collapse {
            padding-left: 0;
            padding-right: 0;
        }

        .grid-container {
            padding-left: 0;
            padding-right: 0;
        }
    }

    .grid-x {
        display: grid;
        grid-template-columns: repeat(12, minmax(0, 1fr));
        margin-left: 0;
        margin-right: 0;

        @if (type-of($grid-margin-gutters) == 'map') {
            @include -zf-breakpoint-value(auto, $grid-margin-gutters) {
                grid-gap: 0 rem-calc($-zf-bp-value);
            }
        }
        @else if (type-of($grid-margin-gutters) == 'number') {
            grid-gap: 0 rem-calc($grid-margin-gutters);
        }

        &.gutters-none {
            grid-gap: 0;
        }

        &.gutters-both {
            @if (type-of($grid-margin-gutters) == 'map') {
                @include -zf-breakpoint-value(auto, $grid-margin-gutters) {
                    grid-gap: rem-calc($-zf-bp-value);
                }
            }
            @else if (type-of($grid-margin-gutters) == 'number') {
                grid-gap: rem-calc($grid-margin-gutters);
            }
        }

        & > .cell {
            width: 100%;
            margin-left: 0;
            margin-right: 0;
        }
    }

    @include -zf-each-breakpoint() {
        // Responsive width modifiers
        @for $i from 1 through $grid-columns {
            .grid-x > .#{$-zf-size}-#{$i} {
                grid-column-end: span #{$i};
            }
        }

        @for $i from 1 through $xy-block-grid-max {
            .#{$-zf-size}-up-#{$i} {
                grid-template-columns: repeat($i, minmax(0, 1fr));
            }
        }

        @for $i from 1 through $grid-columns {
            .grid-x > .#{$-zf-size}-column-#{$i} {
                grid-column-start: #{$i};
            }
        }
    }
}
